import React from "react";
import { useSelector } from "react-redux";
import Header from "../../../../containers/Header";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import Footer from "../../../../containers/Footer";

import SiteLogo from "../../../../assets/images/banner/promotions/Icon.webp";

function VIPLevel() {
  const { isAuth } = useSelector((state) => state.auth);

  return (
    <>
      {isAuth ? <HeaderAfterLogin /> : <Header />}

      <main className="main">
        <div className="vip-gifts vip-level PrmotionGameBox">
          <div className="inner-box">
            <div className="content-box">
              <div className="heading">Trending Games</div>

              <div className="content-box ">
                {/* <h5>Top 5 promoter of the month</h5> */}
                <ul className="">
                  <li className="bonus-card">
                    <a href="/casino/spribe/aviator">
                      <div className="BonusMainImg">
                        <div className="BonusMainContent">
                          <p>Aviator</p>
                        </div>
                        <img src={SiteLogo} alt="bronze" className="logo" />
                      </div>
                    </a>
                  </li>
                  <li className="bonus-card">
                    <a href="/casino/ezgevo-crazy-time">
                      <div className="BonusMainImg">
                        <div className="BonusMainContent">
                          <p>Crazy Time</p>
                        </div>
                        <img src={SiteLogo} alt="bronze" className="logo" />
                      </div>
                    </a>
                  </li>
                  <li className="bonus-card">
                    <a href="/casino/ezgevo-dead-or-alive-saloon">
                      <div className="BonusMainImg">
                        <div className="BonusMainContent">
                          <p>Dead Or Alive</p>
                        </div>
                        <img src={SiteLogo} alt="bronze" className="logo" />
                      </div>
                    </a>
                  </li>
                  <li className="bonus-card">
                    <a href="/casino/ezgevo-football-studio">
                      <div className="BonusMainImg">
                        <div className="BonusMainContent">
                          <p>Football Studio</p>
                        </div>
                        <img src={SiteLogo} alt="bronze" className="logo" />
                      </div>
                    </a>
                  </li>
                  <li className="bonus-card">
                    <a href="/casino/qtechevolutiongaming-gold-vault-roulette">
                      <div className="BonusMainImg">
                        <div className="BonusMainContent">
                          <p>Gold Vault Roulette</p>
                        </div>
                        <img src={SiteLogo} alt="bronze" className="logo" />
                      </div>
                    </a>
                  </li>
                  <li className="bonus-card">
                    <a href="/casino/qtechevolutiongaming-gold-vault-roulette">
                      <div className="BonusMainImg">
                        <div className="BonusMainContent">
                          <p>Lightning Roulette</p>
                        </div>
                        <img src={SiteLogo} alt="bronze" className="logo" />
                      </div>
                    </a>
                  </li>
                  <li className="bonus-card">
                    <a href="/casino/ezgevo-stock-market">
                      <div className="BonusMainImg">
                        <div className="BonusMainContent">
                          <p>Stock Market</p>
                        </div>
                        <img src={SiteLogo} alt="bronze" className="logo" />
                      </div>
                    </a>
                  </li>
                  <li className="bonus-card">
                    <a href="/casino/ezgevo-super-sic-bo">
                      <div className="BonusMainImg">
                        <div className="BonusMainContent">
                          <p>Super Sic Bo</p>
                        </div>
                        <img src={SiteLogo} alt="bronze" className="logo" />
                      </div>
                    </a>
                  </li>
                  <li className="bonus-card">
                    <a href="/casino/ezgevo-xxxtreme-lightning-roulette">
                      <div className="BonusMainImg">
                        <div className="BonusMainContent">
                          <p>XXXtreme Lightning Roulette</p>
                        </div>
                        <img src={SiteLogo} alt="bronze" className="logo" />
                      </div>
                    </a>
                  </li>
                  <li className="bonus-card">
                    <a href="/casino/ezg-ultimate-andar-bahar">
                      <div className="BonusMainImg">
                        <div className="BonusMainContent">
                          <p>Ultimate Andar Bahar</p>
                        </div>
                        <img src={SiteLogo} alt="bronze" className="logo" />
                      </div>
                    </a>
                  </li>
                  <li className="bonus-card">
                    <a href="/casino/aura-matka">
                      <div className="BonusMainImg">
                        <div className="BonusMainContent">
                          <p>matka</p>
                        </div>
                        <img src={SiteLogo} alt="bronze" className="logo" />
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </main>

      {!isAuth ? <BeforeLoginFooter /> : <Footer />}
    </>
  );
}

export default VIPLevel;
